import type { NextPage } from 'next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CompanyLogo } from 'ui/components/company-logo';

const Home: NextPage = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={12} sx={{ svg: { width: '200px' } }}>
        <CompanyLogo />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h1" color="initial">
          Aplicación de Cotizador
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Home;
